import { useEffect } from 'react'
import { Routes, Route, useLocation } from 'react-router-dom'
import { HomePage } from './pages/HomePage'
import { ShopPage } from './pages/ShopPage'
import { ShopDetailPage } from './pages/ShopDetailPage'
import { ContactPage } from './pages/ContactPage'
import { CheckoutPage } from './pages/CheckoutPage'
import { CartPage } from './pages/CartPage'
import { NotfoundPage } from './pages/NotfoundPage'
import { Layout } from './components/Layout'
import { CartProvider } from 'react-use-cart'
/**
 * In this project, I decided to use scss, so that I could easily type it
 * and have additional features like file separation and the use of constants
 */
import './assets/scss/style.scss'

/**
 * The NotfoundPage works as a 404 page, if the path is incorrect then it will not load this page.
 */
function App() {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])
  return (
    <>
      <CartProvider>
        <Routes>
          <Route path='/' element={<Layout />}>
            <Route index element={<HomePage />} />
            <Route path='shop' element={<ShopPage />} />
            <Route path='shop/:furnitureId' element={<ShopDetailPage />} />
            <Route path='contact' element={<ContactPage />} />
            <Route path='checkout' element={<CheckoutPage />} />
            <Route path='cart' element={<CartPage />} />
            <Route path='*' element={<NotfoundPage />} />
          </Route>
        </Routes>
      </CartProvider>
    </>
  )
}

export default App
