import React from 'react'
import { NavLink } from 'react-router-dom'
import { ReactComponent as Delete } from '../assets/img/svg/delete.svg'
import { useCart } from 'react-use-cart'
function CartPage() {
  const { items, cartTotal, updateItemQuantity, removeItem } = useCart()
  return (
    <>
      <div className='banner-breadcrumbs'>
        <img src={require(`../assets/img/bg-breadcrumbs.png`)} alt='' />
        <div className='container'>
          <div className='banner-breadcrumbs__inner'>
            <div className='banner-breadcrumbs-title'>Cart</div>
            <div className='banner-breadcrumbs-list'>
              <NavLink to={'/'}>Home</NavLink>
              <span>Cart</span>
            </div>
          </div>
        </div>
      </div>
      <div className='cart'>
        <div className='container'>
          <div className='cart__inner'>
            <div className='cart-table-box'>
              <table className='cart-table'>
                <thead>
                  <tr>
                    <th>Product</th>
                    <th>Price</th>
                    <th className='cart-table-quantity'>Quantity</th>

                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {items.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          <div className='cart-table-product'>
                            <div className='cart-table-product-img'>
                              <img
                                src={require(`../assets/img/furniture/${item.img[0]}`)}
                                alt=''
                              />
                            </div>
                            {item.title}
                          </div>
                        </td>
                        <td>${item.price.toFixed(2)}</td>
                        <td className='cart-table-quantity'>
                          <div className='furniture-cart-info-counter'>
                            <button
                              onClick={() => {
                                updateItemQuantity(item.id, item.quantity - 1)
                              }}
                            >
                              -
                            </button>
                            {item.quantity}
                            <button
                              onClick={() => {
                                updateItemQuantity(item.id, item.quantity + 1)
                              }}
                            >
                              +
                            </button>
                          </div>
                        </td>

                        <td>
                          <button onClick={() => removeItem(item.id)}>
                            <Delete />
                          </button>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>

            <div className='cart-panel'>
              <div className='cart-panel-title'>Cart Totals</div>
              <div className='cart-panel-subtotal'>
                <span>Subtotal</span>
                <span>${cartTotal.toFixed(2)}</span>
              </div>
              <div className='cart-panel-total'>
                <span>Total</span>
                <span>${cartTotal.toFixed(2)}</span>
              </div>
              <NavLink to='/checkout' className='btn'>
                Check Out
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export { CartPage }
