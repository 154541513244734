import React, { useState } from 'react'
import { NavLink, useParams } from 'react-router-dom'
import furnitureData from '../data/furnitureData.json'
import { FurnitureCard } from '../components/FurnitureCard'
import { ReactComponent as Stars } from '../assets/img/svg/stars.svg'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/free-mode'
import 'swiper/css/thumbs'
import 'swiper/css/effect-fade'
import { FreeMode, EffectFade, Thumbs } from 'swiper/modules'
import { useCart } from 'react-use-cart'

function ShopDetailPage() {
  const [thumbsSwiper, setThumbsSwiper] = useState(null)

  const { furnitureId } = useParams()

  const thisFurniture = furnitureData.find(
    (prod) => String(prod.id) === furnitureId
  )
  const { addItem } = useCart()

  return (
    <>
      <div className='breadcrumbs'>
        <div className='container'>
          <div className='breadcrumbs-list'>
            <NavLink to={'/'}>Home</NavLink>
            <NavLink to={'/shop'}>Shop</NavLink>
            <span>{thisFurniture.title}</span>
          </div>
        </div>
      </div>
      <div className='furniture-cart'>
        <div className='container'>
          <div className='furniture-cart__inner'>
            <div className='furniture-cart-imgslider-box'>
              <div className='furniture-cart-imgslider'>
                <Swiper
                  onSwiper={setThumbsSwiper}
                  slidesPerView={4}
                  freeMode={true}
                  watchSlidesProgress={true}
                  modules={[FreeMode, Thumbs]}
                  className='mySwiper'
                  // breakpoints={{
                  //   1024: {
                  //     spaceBetween: 0,
                  //     slidesPerView: 4
                  //   },
                  //   0: {
                  //     spaceBetween: 10,
                  //     slidesPerView: 4
                  //   },

                  // }}
                >
                  {thisFurniture.img.map((img, idx) => {
                    return (
                      <SwiperSlide key={idx}>
                        <img src={require(`../assets/img/furniture/${img}`)} />
                      </SwiperSlide>
                    )
                  })}
                </Swiper>
                <Swiper
                  spaceBetween={10}
                  thumbs={{
                    swiper:
                      thumbsSwiper && !thumbsSwiper.destroyed
                        ? thumbsSwiper
                        : null,
                  }}
                  modules={[FreeMode, EffectFade, Thumbs]}
                  className='mySwiper2'
                  effect={'fade'}
                >
                  {thisFurniture.img.map((img, idx) => {
                    return (
                      <SwiperSlide key={idx}>
                        <img src={require(`../assets/img/furniture/${img}`)} />
                      </SwiperSlide>
                    )
                  })}
                </Swiper>
              </div>
            </div>

            <div className='furniture-cart-info'>
              <div className='furniture-cart-info-title'>
                {thisFurniture.title}
              </div>
              <div className='furniture-cart-info-price'>
                ${thisFurniture.price}
              </div>
              <div className='furniture-cart-info-stars'>
                <Stars />
                <span>5 Customer Review</span>
              </div>
              <div className='furniture-cart-info-description'>
                {thisFurniture.text}
              </div>
              <div className='furniture-cart-info-color'>
                Color
                <div className='furniture-cart-info-color-items'>
                  <div className='furniture-cart-info-color-item'></div>
                  <div className='furniture-cart-info-color-item blue'></div>
                  <div className='furniture-cart-info-color-item gray'></div>
                </div>
              </div>
              <div className='furniture-cart-info-con'>
               
                <button className='btn' onClick={() => addItem(thisFurniture)}>
                  Add To Cart
                </button>
              </div>
              <div className='furniture-cart-info-list'>
                <div className='furniture-cart-info-list-item'>
                  <div className='furniture-cart-info-list-item-title'>SKU</div>
                  <div className='furniture-cart-info-list-item-text'>
                    <span>:</span>
                    {thisFurniture.sku}
                  </div>
                </div>
                <div className='furniture-cart-info-list-item'>
                  <div className='furniture-cart-info-list-item-title'>
                    Category
                  </div>
                  <div className='furniture-cart-info-list-item-text'>
                    <span>:</span>
                    {thisFurniture.category}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='description'>
        <div className='container'>
          <div className='description__inner'>
            <div className='description-title'>Description</div>
            <p>{thisFurniture.description}</p>
            <div className='description-title-img'>
              <div className='description-title-img-item'>
                <img
                  src={require(`../assets/img/furniture-img-1.jpg`)}
                  alt=''
                />
              </div>
              <div className='description-title-img-item'>
                <img
                  src={require(`../assets/img/furniture-img-2.jpg`)}
                  alt=''
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='sellers'>
        <div className='container'>
          <div className='sellers__inner'>
            <h2>Related Products</h2>
            <div className='sellers-items'>
              {furnitureData.slice(0, 3).map((item, idx) => {
                return (
                  <FurnitureCard
                    key={idx}
                    img={item.img}
                    title={item.title}
                    price={item.price}
                    discountedPrice={item.discountedPrice}
                    page={item.id}
                  />
                )
              })}
            </div>
            <NavLink to='/shop'>Show More</NavLink>
          </div>
        </div>
      </div>
    </>
  )
}

export { ShopDetailPage }
