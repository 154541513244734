import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { ReactComponent as Close } from '../assets/img/svg/close.svg'
import { ReactComponent as CircleX } from '../assets/img/svg/circle-x.svg'
import { useCart } from 'react-use-cart'
function ShopCart({ setIsOpen }) {
  const {
    items,
    cartTotal,
    updateItemQuantity,
    removeItem,
  } = useCart()

  return (
    <>
      <div className='shop-cart-bg' onClick={() => setIsOpen()} />
      <div className='shop-cart-wrapper'>
        <div className='shop-cart'>
          <div className='shop-cart-close' onClick={() => setIsOpen()}>
            <Close />
          </div>
          <div className='shop-cart-top'>
            <div className='shop-cart-title'>Shopping Cart</div>
            <div className='shop-cart-items'>
              {/* <div className='shop-cart-item'>
                <div className='shop-cart-item-img'>
                  <img
                    src={require(`../assets/img/Upholstered-Sofa.jpg`)}
                    alt=''
                  />
                </div>
                <div className='shop-cart-item-box'>
                  <div className='shop-cart-item-box-info'>
                    <div className='shop-cart-item-box-info-title'>
                      Upholstered Sofa
                    </div>
                    <div className='shop-cart-item-box-info-price'>
                      <span>1</span>
                      <span>X</span>
                      $4,899.99
                    </div>
                  </div>
                </div>
                <div className='shop-cart-item-box-icon'>
                  <CircleX />
                </div>
              </div>
              <div className='shop-cart-item'>
                <div className='shop-cart-item-img'>
                  <img
                    src={require(`../assets/img/White-LED-Novelty-Floor-Lamp.jpg`)}
                    alt=''
                  />
                </div>
                <div className='shop-cart-item-box'>
                  <div className='shop-cart-item-box-info'>
                    <div className='shop-cart-item-box-info-title'>
                      White LED Novelty Floor Lamp
                    </div>
                    <div className='shop-cart-item-box-info-price'>
                      <span>1</span>
                      <span>X</span>
                      $243.99
                    </div>
                  </div>
                </div>
                <div className='shop-cart-item-box-icon'>
                  <CircleX />
                </div>
              </div> */}
              {items.map((item, index) => {
          
                return (
                  <div className='shop-cart-item' key={index}>
                    <div className='shop-cart-item-img'>
                      <img
                        src={require(`../assets/img/furniture/${item.img[0]}`)}
                        alt=''
                      />
                    </div>
                    <div className='shop-cart-item-box'>
                      <div className='shop-cart-item-box-info'>
                        <div className='shop-cart-item-box-info-title'>
                          {item.title}
                        </div>
                        <div className='shop-cart-item-box-info-price'>
                          <div className='furniture-cart-info-counter'>
                            <button
                              onClick={() => {
                                updateItemQuantity(item.id, item.quantity - 1)
                              }}
                            >
                              -
                            </button>
                            {item.quantity}
                            <button
                              onClick={() => {
                                updateItemQuantity(item.id, item.quantity + 1)
                              }}
                            >
                              +
                            </button>
                          </div>
                          <span>X</span>${item.price}
                        </div>
                      </div>
                    </div>
                    <button
                      className='shop-cart-item-box-icon'
                      onClick={() => removeItem(item.id)}
                    >
                      <CircleX />
                    </button>
                  </div>
                )
              })}
            </div>
          </div>
          <div className='shop-cart-bottom'>
            <div className='shop-cart-subtotal'>
              Subtotal
              <span>${cartTotal.toFixed(2)}</span>
            </div>
            <div className='shop-cart-btns'>
              <NavLink to='/cart' onClick={() => setIsOpen()}>
                Cart
              </NavLink>
              <NavLink to='/checkout' onClick={() => setIsOpen()}>
                Checkout
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export { ShopCart }
